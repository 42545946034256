.editThemeSong{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#searchButton{
    max-height: 30px;
}

.trackListThemeSong{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 80%;
    align-items: center;
    margin-top: 20px;
}

#submitThemeSong{
    margin-top: 30px;
    width: 60%;
}