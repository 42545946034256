.editProfileNav {
    padding-left: 2em;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
    justify-content: flex-start;
    border-top-left-radius: 10px 12px;
    border-bottom-left-radius: 10px 12px;
}

.editProfile{
    display: flex;
    height: 89%;
    margin: 0 20px 20px 20px;
    border-radius: 10px/12px;
    /* position: relative; */
}

.editProfileSetting {
    display: flex;
    height: 100%;
    margin: 20px;
    width: 80%;
    /* position: relative; */
}

@media only screen and (max-width: 1020px) {
    .editProfile{
        width: 100%;
        border-radius: 5%/5%;
        min-height: 575px;
        height: 80%;
        margin-left: 0;
    }

    .editProfileNav{
        padding: 0;
    }

    a {
        font-size: 16px;
    }

    .editProfileSetting {
        height: 100%;
        margin: 0px;
        width: 80%;
    }
}