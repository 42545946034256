.App {
  text-align: center;
  height: 100vh;
  background: linear-gradient(to right top, #FF2D86 30%, #FFC211 90%);
  color: #372470;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  display: flex;
  justify-content: center;
  /* position: relative; */
}

.App-Content{
  /* position: relative; */
  text-align: center;
  height: 100vh;
  width: 85%;
  max-width: 1155px;
  padding: 30px 0;
}

.glass{
  background: linear-gradient(to right bottom, rgb(255,255,255,.8), rgb(255,255,255,.4));
  backdrop-filter: blur(2rem);
}

.dashboard{
  display:flex;
  height: 92%;
  padding-bottom: 20px;
  padding-right: 20px;
}

.flexCenterColumn {
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.button {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  border-radius: 5px/6px;
  background: linear-gradient(to left bottom, #ff1778 45%, #ebb617);
}

.button{
  border-width: 0;
  border-color:white;
}

.button:hover {
  cursor: pointer;
}

/*animations*/


/*loading*/
.loading-gradient {
  background: repeating-linear-gradient(90deg,#4c3b81cb,#4c3b81cb,#4c3b81cb,#906bff,  #4c3b81cb,#4c3b81cb,#4c3b81cb);
  background-size: 200% 200%;
  color: transparent;
  border-radius: 12px / 10px;
  margin-bottom: 4px;

  -webkit-animation: GreyGradient 2s ease infinite;
  -moz-animation: GreyGradient 2s ease infinite;
  animation: GreyGradient 2s ease infinite;
}

@-webkit-keyframes GreyGradient {
  0%{background-position:0% 50%}
  50%{background-position:50% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes GreyGradient {
  0%{background-position:0% 50%}
  50%{background-position:50% 50%}
  100%{background-position:0% 50%}
}
@keyframes GreyGradient {
  0%{background-position:0% 50%}
  50%{background-position:150% 50%}
  100%{background-position:350% 50%}
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f53d;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: #f5f5f53d;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
  background-color: #4c3b81cb;
}

input {
  background: linear-gradient(to bottom, rgb(255,255,255,1), rgb(223, 223, 223));
  border: 0px;
  border-radius: 4px / 8px;
  height: 30px;
  width: 400px;
  display: flex;
  text-align: center;
  margin-bottom: 15px;
}

.form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1020px) {
  .App {
    text-align: center;
    background: linear-gradient(to right top, #FF2D86 30%, #FFC211 90%);
    color: #372470;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  .App-Content{
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 0;
  }

  input{
    width: 80%;
  }

  .form {
    width: 100%;
  }

  .dashboard{
    padding-bottom: 15px;
    padding-right: 0;
    flex-direction: column;
  }
}