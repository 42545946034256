.my-message{
    background-color: rgb(255, 0, 128);
    color: white;
    margin-left: auto;
}

.lovewav-message{
    background-color: rgb(167, 255, 196);
}

.their-message{
    background-color: rgb(255, 255, 255);
    margin-right: auto;
}

.message{
    border-radius: 10px / 8px;
    padding: 4px;
    margin-bottom: 4px;
}

.messageContainer {
    display: flex;
}

