.tracks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
}

.playlistSlide{
    display: flex;
    height: 80%;
}
