.editYourPlaylist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
}

.yourPlaylists{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 50%;
    width: 80%;
    padding-top: 20px;
}

.currentPlaylist{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

@media only screen and (max-width: 1020px) {
    .yourPlaylists{
        flex-wrap:nowrap;
        overflow-y: scroll;
        padding-top: 0;
        height: 60%;
        max-height: 400px;
    }

    .editYourPlaylist{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
}