.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8%;
    padding: 10px 0 10px 0;
}

.logo{
    padding-left: 30px;
    display: flex;
    align-items: center;
    height: 100%;
}

#logo{
    font-size: 3em;
}

li {
    font-size: larger;
    margin: 30px 40px 30px 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-decoration: none;
}

a{
    text-decoration: none;
    list-style-type: none;
    color: #372470;
}

nav{
    text-decoration: none;
    list-style-type: none;
}

#nav1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    list-style-type: none;
}

@media only screen and (max-width: 1020px) {
    .NavBar{
        flex-direction: column;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    #nav1 {
        height: 15%;
        width: 100%;
        display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    list-style-type: none;
    margin-top: 5%;
    }

    li {
        font-size: larger;
        margin: 0px 0px 0px 0px;
        margin-top: 30px;
        margin-bottom: 30px;
        text-decoration: none;
    }

    .logo{
        padding: 0;
        height: auto;
    }
}