.settings {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px / 8px;
    padding: 20px;
}

@media only screen and (max-width: 1020px) {
    .settings {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px / 8px;
        padding: 20px;
        min-height: 400px;
        margin: 40px 0 150px 0;
    }
}