.profilePicture {
    height: 20em;
    width: 20em;
    min-width: 20em;
    object-fit: cover;
    border-radius: 50%;
    border: 10px solid white;
    margin-top: 15px;
}

.pictureSlide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    padding: 0 0 2em 2em;
}

#percentMatch{
    font-size: 3em;
    color:  #472F8D;
    
}

@media only screen and (max-width: 1020px) {
    .profilePicture{
        height: 12em;
        width: 12em;
    }
}