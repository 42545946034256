.backArrow{
    width: 15px;
    max-height: 32px;
}

.Conversation{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.messageForm{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.messageButton{
    height: 30px;
    margin-top: 0;
    margin-bottom: 15px;
}

.messages{
    height: 80%;
    min-height: 200px;
    overflow-y: scroll;
}

.conversationHeader{
    display: flex;
    height: 25%;
}

.messageInput{
    margin-top: 0px;
}

.userName{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30%;
    margin-bottom: 10px;
}
#lastMessageRefDiv{
    height: 1px;
}

@media only screen and (max-width: 1020px) {
    .Conversation{
        width: 100%;
    }
}