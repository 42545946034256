.MatchDashboard{
    background: linear-gradient(to right bottom, rgb(255,255,255,.8), rgb(255,255,255,.4));
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5%;
    margin: 0 20px 20px 20px;
}

.name {
    color: #E0E0E0;
    font-size: 4em;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboardButton {
    margin: 20px;
}

.profileSection{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
    width: 100%;
}

.findMatches{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.nameSection{
    width: 100%;
    height: 25%;
}

@media only screen and (max-width: 1020px) {
    .MatchDashboard{
        width: 100%;
        margin: 0;
        border-radius: 10% / 5%;
        margin-bottom: 15px;
        max-height: 750px;
    }

    .nameSection{
        height: auto;
    }
}