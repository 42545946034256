.ConversationList {
    width: 30%;
    display: flex;
    background: linear-gradient(to right bottom, rgb(255,255,255,.8), rgb(255,255,255,.4));
    border-radius: 10%/5%;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    padding: 25px;
    overflow-y: scroll;
    border-radius: 10%/5% 1% 1% 5%;
}

.convo-container-content{
    /*transition: transform 1s ease-in-out*/
    transition: opacity 1s ease-in-out;
}

.label {
    margin-bottom: .5em;
}


@media only screen and (max-width: 1020px) {
    .ConversationList{
        width: 100%;
        border-radius: 5%/5%;
        min-height: 500px;
    }
}