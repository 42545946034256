.loadingSlideTitle{
    margin-bottom: 10px;
    margin-top: 20px;
}

.h3-loading{
    margin-left: 5px;
}

.loadingPictureSlide{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    padding: 0 0 2em 2em;
}

