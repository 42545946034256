.Profile {
    width: 85%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    border-radius: 5% 1% 1% 5%;
    flex-direction: column;
}

hr{
    width: 40%;
    border-color: #372470;
}

.entireProfile{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5% 1% 1% 5%;
    width: 80%;
    height: 90%;
}

.albumCover{
    width: 60%;
    height: auto;
}

.slideTitle{
    width: 100%;
    height: 20%;
    display: flex;
    text-align: left;
    align-items: center;
    padding: 1em 0 0px 1em;
    font-size: 32px;
}

.songDetailsSlide{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    padding: 0 0 2em 2em;
}

.songDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
}

@media only screen and (max-width: 1020px) {
    .Profile{
        width: 100%;
        margin: 0;
        border-radius: 10% / 5%;
        margin-bottom: 15px;
        margin-right: 30px;
        padding: 15px;
    }

    .entireProfile{
        width: 100%;
        height: 100%;
    }
}