.trackList {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
}

.track {
    display: flex;
    width: 500px;
    padding: .15em;
    align-items: center;
    height: 30px;
    white-space: nowrap;
    overflow-x: hidden;
}


.title {
    font-weight: 700;
}

.sampleButton {
    height: 80%;
    margin-right: .5em;
}

@media only screen and (max-width: 1020px) {
    .trackList{
        flex-wrap: wrap;
    }

    .songText{
        width: 100%;
        max-width: 150px;
        white-space: nowrap;
        overflow-x: scroll;
    }

    .track{
        width: 100%;
        max-width: 300px;
        font-size: 12px;
    }
    .currentThemeSong{
        padding-top: 20px;
    }
}