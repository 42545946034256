.popup{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(206, 206, 206, 0.35);
    backdrop-filter: blur(1rem);
}

.dashboard-popup{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    right: -11.1%;
    z-index: 5;
    background-color: rgba(206, 206, 206, 0.35);
    backdrop-filter: blur(1rem);
    overflow: hidden;
}

.window {
    border-radius: 8px / 4px;
    width: 60%;
    max-width: 1000px;
    margin-left: 40px;
    padding: 40px 60px;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.x{
    position: absolute;
    right: 4%;
    top: 7%;
}

@media only screen and (max-width: 1020px) {
    .popup{
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .dashboard-popup{
        height:100%;
    }

    .window{
        width: 100%;
        text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    }
}