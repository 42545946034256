.lyrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
    text-align: left;
    padding-top: 30px;
    padding-left: 30px;
}

.lyricSlide{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.lyric {
    padding: .5em;
}

.lyricImage {
    width: 20%;
    height: 30%;
    object-fit: cover;
    margin-right: 2em;
}