.ConversationPreview{
    max-height: 100px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3em 0 3em 0;
}

.conversationIcon {
    border-radius: 50%;
    height: 5em;
    min-width: 5em;
    object-fit: cover;
}

.conversationPreviewText {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 20px;
}

.lastText{
    font-weight: 400;
}